// pages/_app.tsx
import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { AuthProvider } from '../components/AuthContext';
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query';
import { useEffect, useState } from 'react';
import { Roboto } from 'next/font/google';
import ErrorBoundary from '../components/ErrorBoundary';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { FavoritesProvider } from '../contexts/FavoritesContext';
import CookieConsent from '../components/CookieConsent';

const roboto = Roboto({
  weight: ['400', '700'],
  subsets: ['latin'],
  display: 'swap',
});

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30,
        retry: 2,
        refetchOnWindowFocus: false,
      },
    },
  }));

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (typeof window !== 'undefined') {
        window.gtag('config', 'G-ZVQFPDXFXW', { page_path: url });
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      {/* Google Analytics */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-ZVQFPDXFXW"
        strategy="lazyOnload"
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-ZVQFPDXFXW', {
            page_path: window.location.pathname,
          });
        `}
      </Script>

      <AuthProvider>
        <FavoritesProvider>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <ErrorBoundary>
                <main className={roboto.className}>
                  <Component {...pageProps} />
                </main>
                <CookieConsent /> {/* 追加 */}
              </ErrorBoundary>
            </Hydrate>
          </QueryClientProvider>
        </FavoritesProvider>
      </AuthProvider>
    </>
  );
}

export default MyApp;
