// components/CookieConsent.tsx
import React, { useEffect, useState } from 'react';
import { setCookie, getCookie } from '../lib/cookies';

const COOKIE_NAME = 'user_cookie_consent';
const COOKIE_EXPIRY_DAYS = 365; // 1年間

const CookieConsent: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const consent = getCookie(COOKIE_NAME);
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    setCookie(COOKIE_NAME, 'true', COOKIE_EXPIRY_DAYS);
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      id="cookie-consent-banner"
      className="fixed bottom-0 left-0 right-0 bg-gray-100 p-4 text-center z-50 shadow-lg flex flex-col sm:flex-row justify-center items-center"
      style={{
        pointerEvents: "auto",
      }}
    >
      <p className="text-gray-700 mb-4 sm:mb-0 sm:mr-4">
        当サイトではCookieを使用してデータを収集します。同意しますか？
      </p>
      <button
        id="accept-button"
        onClick={handleAccept}
        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
      >
        同意する
      </button>
    </div>
  );
};

export default CookieConsent;
