import React, { createContext, useContext, useState } from 'react';

interface FavoritesContextProps {
  triggerUpdate: () => void;
}

const FavoritesContext = createContext<FavoritesContextProps | undefined>(undefined);

export const FavoritesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [, setFavoritesUpdated] = useState(false);

  const triggerUpdate = () => {
    setFavoritesUpdated((prev) => !prev);
  };

  return (
    <FavoritesContext.Provider value={{ triggerUpdate }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavoriteContext = (): FavoritesContextProps => {
  const context = useContext(FavoritesContext);
  if (!context) {
    throw new Error('useFavoriteContext must be used within a FavoritesProvider');
  }
  return context;
};
