import Link from 'next/link';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6 mt-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center">
          <div className="text-center md:text-left">
            <Link href="/" className="text-lg font-semibold cursor-pointer">
              Kosumiru
            </Link>
            <p className="text-sm mt-2">
              欲しいコスメの最安値を<br />
              見つけよう
            </p>
          </div>
          <div className="flex justify-center mt-4 md:mt-0 space-x-4">
            <Link href="/aboutus" className="text-sm hover:underline">
              About Us
            </Link>
            <Link href="/privacy-policy" className="text-sm hover:underline">
              Privacy Policy
            </Link>
            <Link href="/contact" className="text-sm hover:underline">
              Contact
            </Link>
          </div>
        </div>
        <div className="mt-6 border-t border-gray-600 pt-4 text-center">
          <p className="text-xs">&copy; 2024 corecty Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
