import { Product, SKU } from '../types/types';

export const groupProductsByName = (products: Product[] | null | undefined): { [key: string]: Product[] } => {
  if (!Array.isArray(products)) {
    console.warn("groupProductsByName called with non-array:", products);
    return {};
  }

  const groupedProducts: { [key: string]: Product[] } = {};
  products.forEach((product) => {
    const name = product.name.trim().toLowerCase();
    if (!groupedProducts[name]) {
      groupedProducts[name] = [];
    }
    groupedProducts[name].push(product);
  });
  return groupedProducts;
};

export const sortSkus = (skus: SKU[]): SKU[] => {
  return [...skus].sort((a, b) => {
    const isNumericSku = (sku: string) => /^\d+$/.test(sku);
    const parseSku = (sku: string): { text: string; number: number } => {
      const match = sku.match(/^([a-zA-Z]*)(\d+)$/);
      if (match) {
        const [, text, number] = match;
        return { text, number: parseInt(number, 10) };
      }
      return { text: sku, number: -1 };
    };
    const aIsNumeric = isNumericSku(a.sku);
    const bIsNumeric = isNumericSku(b.sku);
    if (aIsNumeric && !bIsNumeric) {
      return -1;
    }
    if (!aIsNumeric && bIsNumeric) {
      return 1;
    }
    const aParsed = parseSku(a.sku);
    const bParsed = parseSku(b.sku);
    if (aParsed.text < bParsed.text) return -1;
    if (aParsed.text > bParsed.text) return 1;
    return aParsed.number - bParsed.number;
  });
};

export const getThumbnailImage = (product: Product): string => {
  const defaultSku = product.skus?.find(sku => sku.images?.some(image => image.is_default));
  if (defaultSku && defaultSku.images && defaultSku.images.length > 0) {
    let thumbnail = defaultSku.images[0].image_url;
    if (thumbnail && thumbnail !== '') {
      if (thumbnail.startsWith('http://')) {
        thumbnail = thumbnail.replace('http://', 'https://');
      }
      return thumbnail;
    }
  }

  const sortedSkus = sortSkus(product.skus || []);
  let thumbnail = sortedSkus[0]?.images[0]?.image_url;

  if (!thumbnail || thumbnail === '') {
    thumbnail = product.image_full_url || '/images/placeholder.png';
  }

  if (thumbnail.startsWith('http://')) {
    thumbnail = thumbnail.replace('http://', 'https://');
  }

  return thumbnail;
};
