import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Product } from '../types/types';
import { getThumbnailImage } from '../utils/productUtils';

const ProductGroup: React.FC<{
  primaryProduct: Product;
  groupMinPrice: number | null;
  isLCP?: boolean;
}> = React.memo(({ primaryProduct, groupMinPrice, isLCP = false }) => {
  const thumbnail = getThumbnailImage(primaryProduct) || '/images/placeholder.png';

  return (
    <div
      className="inline-block w-40 h-64 bg-white border rounded-lg shadow-sm flex-shrink-0"
    >
      <Link href={`/products/${primaryProduct.id}`}>
        <a>
          <div className="w-full h-36 overflow-hidden rounded-t-lg p-1 bg-white">
            <Image
              src={thumbnail}
              alt={primaryProduct.name}
              width={160}
              height={144}
              priority={isLCP}
              quality={85}
              className="w-full h-full object-contain"
              placeholder="blur"
              blurDataURL="/static/images/placeholder.png"
              sizes="(max-width: 768px) 100vw,
                     (max-width: 1200px) 50vw,
                     33vw"
            />
          </div>
          <div className="p-2">
            <h3 className="text-sm font-semibold break-words line-clamp-2">{primaryProduct.name}</h3>
            <p className="text-xs text-gray-500">{primaryProduct.brand || 'ブランド不明'}</p>
            <p className="text-base font-bold text-red-500">
              {groupMinPrice !== null && groupMinPrice !== undefined && !isNaN(groupMinPrice)
                ? `最安値: ¥${Number(groupMinPrice).toLocaleString()}`
                : '価格不明'}
            </p>
          </div>
        </a>
      </Link>
    </div>
  );
});

ProductGroup.displayName = 'ProductGroup';

export default ProductGroup;
